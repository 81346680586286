import styled, { css } from 'styled-components'
import Slider from 'react-slick'

import { LazyImage } from 'components/atoms/Image'
import { ButtonPrimaryContrast } from 'components/atoms/Button'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import media from 'styles/media'

import angleDown from 'assets/icons/angle-down.svg'

export const Wrapper = styled.section`
  position: relative;
  padding: 124px 0;
  background-color: ${({ theme }) => theme.colors.white};

  ${media.lg.max} {
    padding: 64px 0;
  }
`

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
`

export const NavigationTabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const NavigationTab = styled.button<{ active: boolean }>`
  position: relative;
  margin: 0 0 0 44px;
  padding: 0;
  font-size: 40px;
  line-height: 56px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.15);
  background-color: transparent;
  border: 0;
  text-transform: uppercase;
  transition: color 300ms ease-in-out;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px;
    height: 4px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.black};
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }
  &:first-child {
    margin: 0;
  }

  ${media.lg.max} {
    margin: 0 0 0 32px;
    font-size: 16px;
    line-height: normal;
  }

  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.black};
      &:before {
        opacity: 1;
      }
    `}
`

export const Content = styled.div`
  margin: 64px 0 0 0;
`

export const Products = styled.div`
  display: flex;
  flex-direction: column;
`

export const Product = styled.article`
  position: relative;
`

export const ProductHeader = styled.div`
  margin: 0 0 64px 0;
`

export const ProductText = styled.div`
  font-size: 25px;
  line-height: 33px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
  text-align: center;

  ${media.lg.max} {
    font-size: 18px;
    line-height: 26px;
  }
`

export const ProductContent = styled.div``

export const ProductGallery = styled(Slider)`
  padding: 0 0 24px 0;
  .slick-dots {
    bottom: 0;
    li {
      margin: 0 8px;
      padding: 0;
      height: auto;
      width: auto;
      button {
        height: 16px;
        width: 16px;
        padding: 0;
        &:before {
          content: '';
          top: 2px;
          left: 2px;
          height: 12px;
          width: 12px;
          background-color: ${({ theme }) => theme.colors.black};
          border-radius: 12px;
          transition: opacity 300ms ease-in-out;
        }
      }
    }
  }
`

export const ProductPhoto = styled(LazyImage)`
  width: 100%;

  ${media.lg.min} {
    height: 500px;
  }
`

export const ProductFooter = styled.div`
  margin: 64px auto 0 auto;
  display: flex;
  align-items: center;
  max-width: 860px;

  ${media.lg.max} {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
  }
`

export const ProductPrice = styled.p`
  font-weight: 400;
  font-size: 35px;
  line-height: 35px;
  color: ${({ theme }) => theme.colors.black};
  strong {
    font-weight: 700;
  }

  ${media.lg.max} {
    font-size: 30px;
    line-height: 38px;
  }
`

export const ProductContact = styled.button`
  ${ButtonPrimaryContrast}

  margin: 0 0 0 auto;

  ${media.lg.max} {
    margin: 16px 0 0 0;
  }
`

export const ProductSpec = styled.div`
  margin: 96px auto 0 auto;
  max-width: 860px;

  ${media.lg.max} {
    max-width: 100%;
    width: 100%;
  }
`

export const ProductSpecTitle = styled.h3`
  margin: 0 0 32px 0;
  font-size: 30px;
  line-height: 38px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black};
`

export const ProductSpecList = styled.ul`
  display: flex;
  flex-direction: column;
`

export const ProductSpecTabRow = styled.div`
  display: flex;
  padding: 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }

  ${media.lg.max} {
    flex-direction: column;
  }
`

export const ProductSpecTabHeader = styled.div`
  position: relative;
  padding: 24px 32px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  transition: background-color 300ms ease-in-out;
  &:before {
    content: '';
    position: absolute;
    right: 32px;
    top: 50%;
    width: 32px;
    height: 32px;
    background-image: url(${angleDown});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px;
    transform: translateY(-50%) rotate(-180deg);
    filter: brightness(0) invert(1);

    ${media.lg.max} {
      right: 16px;
      background-size: 16px;
    }
  }

  ${media.lg.max} {
    padding: 16px 24px;
  }
`

export const ProductSpecSubtitle = styled.p`
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
`

export const ProductSpecTabContent = styled.div`
  padding: 32px;
  animation: reveal 0.3s ease-out;
  transform-origin: top;
  @keyframes reveal {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
  background-color: ${({ theme }) => theme.colors.gray5};
  ${media.lg.max} {
    padding: 24px;
  }
`

export const ProductSpecTab = styled.li<{ active: boolean }>`
  margin: 0 0 18px 0;
  &:last-child {
    margin: 0;
  }

  ${media.lg.max} {
    margin: 0 0 8px 0;
  }

  ${({ active }) =>
    active &&
    css`
      ${ProductSpecTabHeader} {
        background-color: ${({ theme }) => theme.colors.gray50};
        &:before {
          filter: unset;
          transform: translateY(-50%) rotate(0);
        }
      }

      ${ProductSpecSubtitle} {
        color: ${({ theme }) => theme.colors.black};
      }
    `}
`

export const ProductSpecLabel = styled.p`
  padding: 0 32px 0 0;
  width: 30%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black};
  ${media.lg.max} {
    width: 100%;
  }
`

export const ProductSpecValue = styled.div`
  width: 70%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
  ${media.lg.max} {
    margin: 4px 0 0 0;
    width: 100%;
  }
`

export const ProductSpecification = styled.div`
  margin: 96px auto 0 auto;
  max-width: 860px;
  ${media.lg.max} {
    max-width: 100%;
    width: 100%;
  }
`

export const ProductSpecificationHeader = styled.div`
  margin: 0 0 32px 0;
`

export const ProductSpecificationTitle = styled.div`
  margin: 0;
  font-size: 30px;
  line-height: 38px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black};
`

export const ProductSpecificationContent = styled.div``
